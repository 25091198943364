import React, { useEffect, useState, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Col, Row, Checkbox, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import _, { isUndefined, omitBy } from 'lodash';
import moment from 'moment';
import { useField } from 'formik';
import SubmitButton from '../../../components/common/form/Submit';
import Select from '../../../components/common/form/Select';
import Input from '../../../components/common/form/Input';
import { createIssuedContract, deleteIssuedContract } from '../../../redux/modules/issuedContract';
import {
  selectedGapQuote,
  selectedVscQuote,
  selectedTwpQuote,
  storeRateFormValues,
  clearState,
} from '../../../redux/modules/newQuote';
import {
  quoteTypeDifference,
  quoteTypeIncludesGAP,
  quoteTypeIncludesTWP,
  quoteTypeIncludesVSC,
  quoteTypeIntersection,
  quoteTypeTitle,
} from '../../../constants/quoteType';
import { notifSend } from '../../../redux/modules/notifs';
import { contractTypeTitle } from '../../../constants/contractType';
import {
  money,
  stringErrorTest,
  minLoanAmountFlat,
  maxLoanAmountYup,
  requiredIfTrue,
  maxRetailGap,
  isZipCode,
  isOnlyAlphabetWithSymbols,
  integerPhoneNumber,
  isEmail,
  matchCI,
  notMatchCI,
  maxKelleyBlueBookFlat,
  isPersonName,
  moneyTypeErrorMsg,
  monthsTypeErrorMsg,
  ifCondition,
} from '../../../components/common/form/schema/common';
import ContractTypeCheckboxes from './form/ContractTypeCheckboxes';
import { abbreviationFromState } from '../../../constants/general';
import { quoteTypeIncludesVSCxTWP } from '../../../constants/quoteType';
import {
  vscRateTestDataIfRequested,
  gapRateTestDataIfRequested,
  twpRateTestDataIfRequested,
  customerInformationRateTestDataIfRequested,
  lenderInformationRateTestDataIfRequested,
} from '../../../utils/testData';
import { mapQueryValuesFromQueryData } from '../../../utils/decorators';
import { statesForSelection } from '../../../constants/general';
import AvailableLenders from './form/AvailableLenders';
import { getZipCodeAddress } from '../../../redux/modules/zipCode';
import { TaskEventsList } from '../../Tasks/EventsList';
import NotificationModal from '../../../components/common/NotificationModal';
import api from '../../../utils/service';
import {
  isKelleyBlueBookDisabled,
  maxRetailPriceOfVsc,
} from '../../../components/validation/rateValidation';
import { formikSetFieldsHelper } from '../../../utils/formikSetFieldsHelper';
import { collapseName } from '../../../components/common/normalize/name';
import { collapseNumberMoneyHideZeroCents } from '../../../components/common/normalize/numberMoney';
import { collapseNumberInt } from '../../../components/common/normalize/numberInt';
import { collapseUSPhone } from '../../../components/common/normalize/USPhone';
import { BHPH_OPTIONS } from '../constants';
import { WithUnloadHandler, unregisterUnloadHandler } from '../../new-quote/WithUnloadHandler';
import { isAllPlansSelected } from '../isAllPlansSelected';
import { InterceptNavigation } from '../../new-quote/WithUnloadHandler';

const CONTRACT_LANGUAGE_OPTIONS = [
  {
    value: 'EN',
    label: 'English',
  },
  {
    value: 'SP',
    label: 'Spanish',
  },
];

export const isLanguageDisabled = ({ quoteType, dealerState }) =>
  !quoteTypeIncludesGAP(quoteType) && _.includes(['IN', 'ID'], dealerState);

export const isRetailPriceOfVscRequired = ({ quoteType }) => quoteTypeIncludesVSCxTWP(quoteType);

export const formatSaleDateMoment = saleDate => {
  if (moment.isMoment(saleDate)) {
    return saleDate.format('MM/DD/YYYY');
  } else if (saleDate) {
    return saleDate;
  } else {
    return moment().format('MM/DD/YYYY');
  }
};

const MAXIMUM_RETAIL_PRICE_GAP = 2000;

export const isKelleyBlueBookRequired =
  ({ dealerState, quoteType }) =>
  ({ bhph }) =>
    (dealerState === 'CA' && quoteTypeIncludesGAP(quoteType)) || bhph === 'Yes';

const STATES_VALUES = statesForSelection();

export const customerPhoneNumberRequired = ({ eSign, email }) => !eSign && !email;

export const customerEmailRequired = ({ eSign, phoneNumber }) => eSign || !phoneNumber;

export function Rate({
  quoteInitialization,
  vehicleFormValues,
  allPlansSelected,
  rateFormValues,
  storeRateFormValues,
  clearState,
  router,
  route,
  location,
  loading,
  quoteType,
  saleDate,
  newQuote,
  dealerState,
  retailPriceOfGap,
  contractYear,
  gapContractYear,
  vscQuote,
  gapQuote,
  twpQuote,
  dealer,
  extraCoverages,
  createIssuedContract,
  deleteIssuedContract,
  notifSend,
  maximumLoanAllowed,
}) {
  const [nonEditableLender, onUpdateNonEditableLender] = useState(false);
  const [pendingContract, setPendingContract] = useState(null);
  const [existingContract, setExistingContract] = useState(undefined);
  const [issueError, setIssueError] = useState(false);

  useEffect(() => {
    if (!allPlansSelected) router.replace('/quote/plans');
  }, [router, allPlansSelected]);

  const { signatureFlow } = newQuote;

  const eSign = signatureFlow === 'eSign';

  const schema = useMemo(
    () =>
      Yup.object().shape({
        ...(quoteType && quoteTypeIncludesVSC(quoteType)
          ? {
              vscRate: Yup.object({
                vehiclePrice: Yup.number()
                  .label('Purchase Price of the Vehicle')
                  .test(money)
                  .required('Required'),

                ...(isRetailPriceOfVscRequired({ quoteType })
                  ? {
                      retailPriceOfVsc: Yup.number()
                        .typeError(moneyTypeErrorMsg)
                        .label('Retail price of VSC')
                        .test(money)
                        .test(
                          stringErrorTest((value, { parent: { vehiclePrice } }) =>
                            maxRetailPriceOfVsc(value, { vehiclePrice }),
                          ),
                        )
                        .max(_.toFinite(_.get(vscQuote, 'maximumLiabilityLimit')) || Infinity)
                        .required('Required'),
                    }
                  : {}),
              }),
            }
          : {}),
        ...(quoteType && quoteTypeIncludesGAP(quoteType)
          ? {
              gapRate: Yup.object({
                retailPriceOfGap: Yup.number()
                  .typeError(moneyTypeErrorMsg)
                  .label('Retail Price of GAP')
                  .test(money)
                  .test(maxRetailGap({ dealerState }))
                  .max(
                    Math.min(
                      _.toFinite(_.get(gapQuote, 'maximumLiabilityLimit')) || Infinity,
                      MAXIMUM_RETAIL_PRICE_GAP,
                    ),
                  )
                  .required('Retail Price of GAP is a required field'),
                loanAmountOfGap: Yup.number()
                  .typeError(moneyTypeErrorMsg)
                  .label('The Loan amount of the GAP')
                  .test(money)
                  .min(100.01)
                  .test(
                    stringErrorTest((value, { parent: { kelleyBlueBook } }) =>
                      minLoanAmountFlat(value, {
                        dealerState,
                        gapContractYear,
                        kelleyBlueBook,
                      }),
                    ),
                  )
                  .test(maxLoanAmountYup({ maximumLoanAllowed, dealerState, contractYear })),
                gapLoanTermInMonths: Yup.number()
                  .typeError(monthsTypeErrorMsg)
                  .label('GAP Loan Term')
                  .min(_.get(gapQuote, ['band', 0]))
                  .max(_.get(gapQuote, ['band', 1]))
                  .required('GAP Loan Term is a required field'),
                kelleyBlueBook: Yup.number()
                  .typeError(moneyTypeErrorMsg)
                  .label('Kelley Blue Book')
                  .test(money)
                  .test(
                    stringErrorTest((value, { loanAmountOfGap }) =>
                      maxKelleyBlueBookFlat(value, {
                        dealerState,
                        gapContractYear,
                        loanAmount: loanAmountOfGap,
                      }),
                    ),
                  )
                  .test(requiredIfTrue(isKelleyBlueBookRequired({ dealerState, quoteType }))),
                bhph: Yup.mixed()
                  .label('BHPH')
                  .test((value, { createError }) => {
                    if (!value) return createError({ message: 'Select BHPH status' });

                    return true;
                  }),
              }),
            }
          : {}),
        ...(quoteType && quoteTypeIncludesTWP(quoteType)
          ? {
              twpRate: Yup.object({
                retailPriceOfTwp: Yup.number()
                  .typeError(moneyTypeErrorMsg)
                  .label('Retail price of TWP')
                  .test(money)
                  .max(_.toFinite(_.get(twpQuote, 'maximumLiabilityLimit')) || Infinity)
                  .required('Required'),
              }),
            }
          : {}),
        customer: Yup.object({
          firstName: Yup.string().label('First Name').test(isPersonName).required('Required'),
          middleInitial: Yup.string().label('Middle Initial').test(isPersonName),
          lastName: Yup.string().label('Last Name').test(isPersonName).required('Required'),
          phoneNumber: Yup.string()
            .label('Phone Number')
            .test(integerPhoneNumber)
            .length(10)
            .test(requiredIfTrue(customerPhoneNumberRequired, { eSign })),
          email: Yup.string().test(isEmail).test(requiredIfTrue(customerEmailRequired, { eSign })),
          confirmEmail: Yup.string()
            .test(matchCI('email', "Emails don't match"))
            .test(requiredIfTrue(customerEmailRequired, { eSign })),
          coBuyerFirstName: Yup.string()
            .label('Co-Buyer First Name')
            .test(ifCondition((_, { parent: { hasCoBuyer } }) => hasCoBuyer, isPersonName))
            .test(requiredIfTrue(({ hasCoBuyer }) => hasCoBuyer)),
          coBuyerLastName: Yup.string()
            .label('Co-Buyer Last Name')
            .test(ifCondition((_, { parent: { hasCoBuyer } }) => hasCoBuyer, isPersonName))
            .test(requiredIfTrue(({ hasCoBuyer }) => hasCoBuyer)),
          coBuyerEmail: Yup.string()
            .test(ifCondition((_, { parent: { hasCoBuyer } }) => hasCoBuyer, isEmail))
            .test(
              ifCondition(
                (_, { parent: { hasCoBuyer } }) => hasCoBuyer,
                notMatchCI('email', 'The co-buyer must have a separate email from the buyer'),
              ),
            )
            .test(requiredIfTrue(({ hasCoBuyer }) => hasCoBuyer && eSign)),
          coBuyerConfirmEmail: Yup.string()
            .test(matchCI('coBuyerEmail', "Emails don't match"))
            .test(requiredIfTrue(({ hasCoBuyer }) => hasCoBuyer && eSign)),
          address: Yup.object({
            zipCode: Yup.string().test(isZipCode),
            street: Yup.string().label('Customer address').required('Required'),
            city: Yup.string()
              .label('Customer address city')
              .test(isOnlyAlphabetWithSymbols)
              .required('Required'),
            state: Yup.string().label('Customer address state').required('Required'),
          }),
        }),
        lender: Yup.object({
          companyName: Yup.string()
            .label('Lender company name')
            .min(2)
            .test(
              'no-alpha-numeric-intermix',
              'Company name should not contain numbers and letters intermixed',
              value =>
                !(
                  /(?: |^)\w*[A-Za-z]\d\w*(?: |$)/.test(value) ||
                  /(?: |^)\w*\d[A-Za-z]\w*(?: |$)/.test(value)
                ),
            )
            .required('Required'),
          address: Yup.object({
            street: Yup.string().label('Lender street').required('Required'),
            city: Yup.string().label('Lender city').required('Required'),
            state: Yup.string().label('Lender state').required('Required'),
            zipCode: Yup.string().label('Lender zip code').required('Required').test(isZipCode),
          }),
          phoneNumber: Yup.string()
            .label('Lender phone number')
            .test(integerPhoneNumber)
            .max(10)
            .required('Required'),
        }),
      }),
    [
      quoteType,
      eSign,
      vscQuote,
      gapQuote,
      twpQuote,
      dealerState,
      contractYear,
      gapContractYear,
      maximumLoanAllowed,
    ],
  );

  const [skipCheckInProgress, setSkipCheckInProgress] = useState(false);
  useEffect(() => {
    // Exposing the handler to use for puppeteer testing
    window.__Rate_setSkipCheckInProgress = () => setSkipCheckInProgress(true);

    return () => {
      delete window.__Rate_setSkipCheckInProgress;
    };
  }, []);

  const initializationValues = useMemo(
    () =>
      mapQueryValuesFromQueryData(
        {
          retailPriceOfGap: 'gapRate.retailPriceOfGap',
          loanTerm: 'gapRate.gapLoanTermInMonths',
          bhph: 'gapRate.bhph',
          kelleyBlueBook: 'gapRate.kelleyBlueBook',

          retailPriceOfTwp: 'twpRate.retailPriceOfTwp',

          retailPriceOfVsc: 'vscRate.retailPriceOfVsc',
          stockNumber: 'vscRate.stockNumber',

          customerFirstName: 'customer.firstName',
          customerLastName: 'customer.lastName',
          customerEmail: [
            {
              key: 'customer.email',
              useInitial: true,
            },
            {
              key: 'customer.confirmEmail',
              useInitial: true,
            },
          ],
          customerAddress: 'customer.address.street',
          customerCity: 'customer.address.city',
          customerState: 'customer.address.state',
          customerZip: 'customer.address.zipCode',
          customerPhone: 'customer.phoneNumber',
          coBuyerFirstName: 'customer.coBuyerFirstName',
          coBuyerLastName: 'customer.coBuyerLastName',
          coBuyerEmail: 'customer.coBuyerEmail',
          coBuyerConfirmEmail: 'customer.coBuyerConfirmEmail',

          financierName: 'lender.companyName',
          financierAddress: 'lender.address.street',
          financierCity: 'lender.address.city',
          financierState: 'lender.address.state',
          financierZip: 'lender.address.zipCode',
        },
        quoteInitialization,
      ),
    [quoteInitialization],
  );

  const getCombinedPendingContract = (values = {}) => {
    return {
      ...vehicleFormValues,
      ...values,
      quoteType: vehicleFormValues.quoteType.join(','),
      eSign,
      dealer,
      quotes: {
        vscQuote,
        gapQuote,
        twpQuote,
        extraCoverages,
      },
    };
  };

  useEffect(() => {
    setExistingContract(undefined);
    if (!pendingContract) return undefined;

    let cancelled = false;
    const retrieveExistingContract = async () => {
      const { vin } = pendingContract;
      try {
        /**
         * @typedef ExistingIssuedContract {{
         *  id: string;
         *  created: string;
         *  vin: string;
         *  status: string;
         *  contractType: string;
         *  vscQuoteId: string;
         *  gapQuoteId: string;
         *  twpQuoteId: string;
         * }}
         */
        const { data } = await api.get(`/actions/issued-contracts/vin/${vin}`);
        if (data && data.issuedContract) {
          if (!cancelled) setExistingContract(data.issuedContract);
          return;
        }
      } catch (err) {
        const { response } = err;
        // note: its okay if its not found.. continue submission
        if (response.status !== 404) {
          throw new Error(
            `Error checking for already issued contract: ${response.message || err.toString()}`,
          );
        }
      }
      if (!cancelled) setExistingContract(null);
    };

    retrieveExistingContract();

    return () => {
      cancelled = true;
    };
  }, [pendingContract]);

  const handleConfirmCancelExistingContract = async (cancel, keepContractTypes = null) => {
    if (cancel) {
      await deleteContract(keepContractTypes);
      notifSend({
        message: `Requested cancellation for existing contract on ${pendingContract.vin}`,
        dismissAfter: 5000,
      });
    }
    setExistingContract(null);
  };

  const submitContract = useCallback(
    async contract => {
      setPendingContract(null);
      const result = await createIssuedContract({ ...contract, skipCheckInProgress });

      // Result can be either Task info (when its execution delayed), or the Task execution result.
      const taskId = _.get(result, 'taskId');
      if (taskId) {
        // Got Task info, navigating user to the Task
        unregisterUnloadHandler(true);
        router.push(`/tasks/IssueContract/${taskId}`);
        clearState();
      } else {
        // Got execution result
        const taskEvents = _.get(result, 'taskEvents');
        if (taskEvents) {
          const resultEvent = taskEvents.find(e => e.eventType === 'TaskEventResult');
          if (resultEvent) {
            const { issuedContractId } = resultEvent;
            unregisterUnloadHandler(true);
            router.push(`/contractIssueComplete/${issuedContractId}`);
            clearState();
          } else {
            setIssueError(taskEvents);
          }
        } else {
          setIssueError(true);
        }
      }
    },
    [router],
  );

  useEffect(() => {
    if (pendingContract && existingContract === null) submitContract(pendingContract);
  }, [pendingContract, existingContract, submitContract]);

  const deleteContract = async keepContractTypes => {
    await deleteIssuedContract(existingContract.id, {
      reason: 'duplicate',
      keepContractTypes,
    });
  };

  const handleSubmit = values => {
    const contract = getCombinedPendingContract(values);
    setPendingContract(contract);
    setIssueError(false);
  };

  const [initialValues] = useState(() => {
    const firstInitialValues = rateFormValues
      ? {}
      : {
          vscRate: {
            ...vscRateTestDataIfRequested(),
            language: 'EN',
            ...omitBy(initializationValues.vscRate || {}, isUndefined),
          },
          gapRate: {
            ...gapRateTestDataIfRequested({
              maximumLiabilityLimit: _.get(gapQuote, 'maximumLiabilityLimit'),
              lowerBandOfGap: _.get(gapQuote, ['band', 0]),
              higherBandOfGap: _.get(gapQuote, ['band', 1]),
            }),
            ...omitBy(initializationValues.gapRate || {}, isUndefined),
          },
          twpRate: {
            ...twpRateTestDataIfRequested(),
            ...omitBy(initializationValues.twpRate || {}, isUndefined),
          },
          customer: {
            ...customerInformationRateTestDataIfRequested(),
            ...omitBy(initializationValues.customer || {}, isUndefined),
          },
          lender: {
            ...lenderInformationRateTestDataIfRequested(),
            ...omitBy(initializationValues.lender || {}, isUndefined),
          },
        };

    const dependingValues = {
      vscRate: {
        ...omitBy(
          {
            issueType: _.get(vehicleFormValues, 'quoteType'),
            saleDate,
            vin: _.get(vehicleFormValues, 'vin'),
            vehiclePrice: _.get(vehicleFormValues, 'vehiclePurchasePrice'),
            vehicleMileage: _.get(vehicleFormValues, 'vehicleMileage'),
          },
          isUndefined,
        ),
      },
      gapRate: {
        ...omitBy(
          {
            bhph: _.get(vehicleFormValues, 'bhph'),
            loanAmountOfGap: _.get(vehicleFormValues, 'loanAmount'),
            kelleyBlueBook: _.get(vehicleFormValues, 'kelleyBlueBook'),
            retailPriceOfGap: retailPriceOfGap > 0 ? retailPriceOfGap : undefined,
          },
          isUndefined,
        ),
      },
      twpRate: {},
      customer: {},
      lender: {},
    };

    return {
      vscRate: {
        ...firstInitialValues?.vscRate,
        ...rateFormValues?.vscRate,
        ...dependingValues?.vscRate,
      },
      gapRate: {
        ...firstInitialValues?.gapRate,
        ...rateFormValues?.gapRate,
        ...dependingValues?.gapRate,
      },
      twpRate: {
        ...firstInitialValues?.twpRate,
        ...rateFormValues?.twpRate,
        ...dependingValues?.twpRate,
      },
      customer: {
        ...firstInitialValues?.customer,
        ...rateFormValues?.customer,
        ...dependingValues?.customer,
      },
      lender: {
        ...firstInitialValues?.lender,
        ...rateFormValues?.lender,
        ...dependingValues?.lender,
      },
    };
  });

  return (
    <>
      <div className="container">
        <InterceptNavigation router={router} route={route} location={location} />
        <Formik
          validateOnBlur
          validateOnMount
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({
            handleReset,
            handleSubmit,
            values,
            errors,
            isValid,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            setTouched,
          }) => {
            const {
              gapRate: { bhph },
              customer,
              lender,
            } = values;

            const setFieldsHelper = formikSetFieldsHelper({ setFieldValue, setFieldTouched });

            const customerZipCode = _.get(customer, 'address.zipCode');
            const lenderZipCode = _.get(lender, 'address.zipCode');
            const hasCoBuyer = _.get(customer, 'hasCoBuyer');
            const inputKelleyBlueBook = isKelleyBlueBookRequired({
              dealerState,
              quoteType,
            })({
              bhph,
            });

            useEffect(() => {
              if (!inputKelleyBlueBook) {
                setFieldsHelper([['gapRate.kelleyBlueBook', undefined]]);
              }
            }, [inputKelleyBlueBook]);

            useEffect(() => {
              storeRateFormValues(values);
            }, [values]);

            useEffect(() => {
              if (customerZipCode) {
                getZipCodeAddress(customerZipCode).then(ResponseData => {
                  if (ResponseData.data.length) {
                    setFieldValue('customer.address.city', _.get(ResponseData.data[0], 'city', ''));
                    setFieldValue(
                      'customer.address.state',
                      _.get(ResponseData.data[0], 'state', ''),
                    );
                  }
                });
              }
            }, [customerZipCode]);

            useEffect(() => {
              if (lenderZipCode) {
                getZipCodeAddress(lenderZipCode).then(ResponseData => {
                  if (ResponseData.data.length) {
                    setFieldValue('lender.address.city', _.get(ResponseData.data[0], 'city', ''));
                    setFieldValue('lender.address.state', _.get(ResponseData.data[0], 'state', ''));
                  }
                });
                // TODO test
              }
            }, [lenderZipCode]);

            useEffect(() => {
              if (!hasCoBuyer) {
                const coBuyerKeys = Object.keys(customer)
                  .filter(key => key.startsWith('coBuyer'))
                  .filter(k => customer[k]);
                if (coBuyerKeys.length)
                  setFieldsHelper(coBuyerKeys.map(key => [`customer.${key}`, undefined]));
              }
            }, [customer, hasCoBuyer]);

            const handleLenderSelected = ({ name, address, phoneNumber } = {}) => {
              setFieldsHelper([
                ['lender.companyName', name || ''],
                ['lender.address.street', _.get(address, 'street', '')],
                ['lender.address.city', _.get(address, 'city', '')],
                ['lender.address.state', abbreviationFromState(_.get(address, 'state', ''))],
                ['lender.address.zipCode', _.get(address, 'zipCode', '')],
                ['lender.phoneNumber', phoneNumber || ''],
              ]);

              onUpdateNonEditableLender(!!name);
            };

            const handleLenderEdit = () => {
              onUpdateNonEditableLender(false);
            };

            return (
              <form
                onReset={handleReset}
                noValidate={true}
                onSubmit={handleSubmit}
                data-test-id="VscRateForm"
              >
                {quoteType && quoteTypeIncludesVSC(quoteType) && (
                  <FormGroup>
                    <h3>VSC Details</h3>
                    <Row>
                      <Col xs={12} md={6}>
                        <ContractTypeCheckboxes
                          name="vscRate.issueType"
                          disabled
                          quoteType={quoteType}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <SaleDateInput
                          name="vscRate.saleDate"
                          type="date"
                          label="Sale Date of the Vehicle"
                          placeholder={`eg. ${moment().format('MM/DD/YYYY')}`}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Select
                          name="vscRate.language"
                          label="Contract Language"
                          options={CONTRACT_LANGUAGE_OPTIONS}
                          disabled={isLanguageDisabled({ quoteType, dealerState })}
                          data-test-id="VscRateForm-language"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="vscRate.vehiclePrice"
                          collapse={collapseNumberMoneyHideZeroCents}
                          label="Purchase Price of the Vehicle"
                          placeholder="eg. 25000"
                        />
                      </Col>
                    </Row>
                    <Row>
                      {isRetailPriceOfVscRequired({ quoteType }) && (
                        <Col xs={12} md={6}>
                          <Input
                            name="vscRate.retailPriceOfVsc"
                            collapse={collapseNumberMoneyHideZeroCents}
                            label="Retail Price of VSC"
                            placeholder="eg. 1688"
                            data-test-id="VscRateForm-retailPriceOfVsc"
                          />
                        </Col>
                      )}
                      <Col xs={12} md={6}>
                        <Input name="vscRate.vin" type="text" label="Vehicle VIN" disabled />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input name="vscRate.vehicleMileage" type="text" label="Mileage" disabled />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="vscRate.stockNumber"
                          type="text"
                          label="Stock Number of the Vehicle"
                          placeholder="eg. 25OR624 (OPTIONAL)"
                          data-test-id="VscRateForm-stockNumber"
                        />
                      </Col>
                    </Row>
                    <hr />
                  </FormGroup>
                )}
                {quoteType && quoteTypeIncludesGAP(quoteType) && (
                  <FormGroup>
                    <h3>GAP Details</h3>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="gapRate.retailPriceOfGap"
                          collapse={collapseNumberMoneyHideZeroCents}
                          label="Retail Price of GAP"
                          placeholder="eg. 1200.00"
                          disabled={!!retailPriceOfGap}
                          data-test-id="GapRateForm-retailPriceOfGap"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="gapRate.loanAmountOfGap"
                          collapse={collapseNumberMoneyHideZeroCents}
                          label="Amount Financed of GAP"
                          placeholder="eg. 4500.00"
                          data-test-id="GapRateForm-loanAmountOfGap"
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="gapRate.gapLoanTermInMonths"
                          collapse={collapseNumberInt}
                          label="GAP Loan Term in Months"
                          placeholder="eg. 72"
                          data-test-id="GapRateForm-gapLoanTermInMonths"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Select
                          name="gapRate.bhph"
                          label="BHPH Deal"
                          placeholder="Choose one"
                          options={BHPH_OPTIONS}
                          disabled={true}
                          data-test-id="GapRateForm-bhph"
                        />
                      </Col>
                    </Row>
                    <Row>
                      {inputKelleyBlueBook && (
                        <Col xs={12} md={6}>
                          <Input
                            name="gapRate.kelleyBlueBook"
                            collapse={collapseNumberMoneyHideZeroCents}
                            label="Kelley Blue Book Retail Value"
                            placeholder="eg. 22000"
                            data-test-id="GapRateForm-kelleyBlueBook"
                            disabled={isKelleyBlueBookDisabled({
                              dealerState,
                              gapContractYear,
                              vehicleSaleDate: saleDate,
                            })}
                          />
                        </Col>
                      )}
                    </Row>
                    <hr />
                  </FormGroup>
                )}
                {quoteType && quoteTypeIncludesTWP(quoteType) && (
                  <FormGroup>
                    <h3>T&W Details</h3>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="twpRate.retailPriceOfTwp"
                          collapse={collapseNumberMoneyHideZeroCents}
                          label="Retail Price of T&W"
                          placeholder="eg. 1200.00"
                          data-test-id="TwpRateForm-retailPriceOfTwp"
                        />
                      </Col>
                    </Row>
                    <hr />
                  </FormGroup>
                )}
                <FormGroup>
                  <h3>Customer Information</h3>
                  <Row>
                    <Col xs={12} md={6}>
                      <Input
                        name="customer.firstName"
                        type="text"
                        label="First Name"
                        placeholder="eg. George"
                        data-test-id="CustomerInformationForm-firstName"
                        collapse={collapseName}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Input
                        name="customer.middleInitial"
                        type="text"
                        label="Middle Initial"
                        placeholder="eg. R"
                        collapse={collapseName}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Input
                        name="customer.lastName"
                        type="text"
                        label="Last Name"
                        placeholder="eg. Jones"
                        data-test-id="CustomerInformationForm-lastName"
                        collapse={collapseName}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Input
                        name="customer.address.zipCode"
                        type="text"
                        label="Zip Code"
                        placeholder="eg. 11101, 11101-1234"
                        data-test-id="CustomerInformationForm-zipCode"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Input
                        name="customer.address.street"
                        type="text"
                        label="Address"
                        placeholder="eg. 919 Main St."
                        data-test-id="CustomerInformationForm-street"
                        collapse={collapseName}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Input
                        name="customer.address.city"
                        type="text"
                        label="City"
                        placeholder="eg. Lafayette"
                        data-test-id="CustomerInformationForm-city"
                        collapse={collapseName}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Select
                        name="customer.address.state"
                        label="State"
                        options={STATES_VALUES}
                        data-test-id="CustomerInformationForm-state"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Input
                        name="customer.phoneNumber"
                        type="text"
                        label="Phone Number"
                        placeholder="eg. 8882627890"
                        data-test-id="CustomerInformationForm-phoneNumber"
                        collapse={collapseUSPhone}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Input
                        name="customer.email"
                        type="text"
                        label={
                          <>
                            Email{' '}
                            {eSign && (
                              <small style={{ opacity: 0.6 }}>
                                E-Sign instructions will be sent over
                              </small>
                            )}
                          </>
                        }
                        placeholder="eg. george.jones@superiorautos.com"
                        data-test-id="CustomerInformationForm-email"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Input
                        name="customer.confirmEmail"
                        type="text"
                        label="Confirm"
                        placeholder="eg. george.jones@superiorautos.com"
                        data-test-id="CustomerInformationForm-confirmEmail"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <label>
                        <Field type="checkbox" name="customer.hasCoBuyer" className="mr-2" />I have
                        a co-buyer
                      </label>
                    </Col>
                  </Row>
                  {hasCoBuyer && (
                    <>
                      <Row>
                        <Col xs={12} md={6}>
                          <Input
                            name="customer.coBuyerFirstName"
                            type="text"
                            label="First Name"
                            placeholder="eg. Waylon"
                            collapse={collapseName}
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <Input
                            name="customer.coBuyerLastName"
                            type="text"
                            label="Last Name"
                            placeholder="eg. Jennings"
                            collapse={collapseName}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Input
                            name="customer.coBuyerEmail"
                            type="text"
                            label={
                              <>
                                Email{' '}
                                {eSign && (
                                  <small style={{ opacity: 0.6 }}>
                                    E-Sign instructions will be sent over
                                  </small>
                                )}
                              </>
                            }
                            placeholder="eg. waylon.jennings@worthycarriages.net"
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <Input
                            name="customer.coBuyerConfirmEmail"
                            type="text"
                            label="Confirm"
                            placeholder="eg. waylon.jennings@worthycarriages.net"
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <hr />
                </FormGroup>
                <FormGroup>
                  <h3>Lender Information</h3>
                  <AvailableLenders
                    onLenderSelected={handleLenderSelected}
                    onLenderEdit={handleLenderEdit}
                  />
                  <Row>
                    <Col xs={12} md={6}>
                      <Input
                        name="lender.companyName"
                        type="text"
                        label="Finance Company Name"
                        disabled={nonEditableLender}
                        placeholder="eg. First National Bank"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Input
                        name="lender.address.zipCode"
                        type="text"
                        label="Zip Code"
                        placeholder="eg. 11101"
                        disabled={nonEditableLender}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Input
                        name="lender.address.street"
                        type="text"
                        label="Address"
                        placeholder="eg. 919 Main St."
                        disabled={nonEditableLender}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Input
                        name="lender.address.city"
                        type="text"
                        label="City"
                        placeholder="eg. Lafayette"
                        disabled={nonEditableLender}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Input
                        name="lender.address.state"
                        type="text"
                        label="State"
                        options={STATES_VALUES}
                        disabled={nonEditableLender}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Input
                        name="lender.phoneNumber"
                        type="text"
                        label="Finance Company Phone Number"
                        placeholder="eg. 8882627890"
                        disabled={nonEditableLender}
                      />
                    </Col>
                  </Row>
                  <hr />
                </FormGroup>
                <Row className="margin-top-20px">
                  {' '}
                  <Col xs={12}>
                    <SubmitButton
                      loadingText="Retrieving Quote..."
                      isValid={isValid}
                      isSubmitting={isSubmitting}
                      errors={errors}
                      setTouched={setTouched}
                      data-test-name="VehicleBasicInformationForm-Buttons"
                    >
                      Generate Contracts
                    </SubmitButton>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </div>

      {pendingContract && existingContract && (
        <CreateDuplicateContractConfirmation
          show={!!existingContract}
          disabled={loading}
          onConfirm={keepContractTypes =>
            handleConfirmCancelExistingContract(true, keepContractTypes)
          }
          onClose={() => handleConfirmCancelExistingContract(false)}
          pendingQuoteType={pendingContract.quoteType}
          existingContract={existingContract}
        />
      )}
      {issueError && (
        <div data-test-id="Rate-issueError">
          <TaskEventsList taskEvents={Array.isArray(issueError) ? issueError : []} />
        </div>
      )}
    </>
  );
}

export default compose(
  connect(
    ({ dealer, page, newQuote }) => {
      const saleDate = _.get(newQuote, 'vehicleFormValues.vehicleSaleDate');
      const quoteType = _.get(newQuote, 'vehicleFormValues.quoteType');
      const vscQuote = selectedVscQuote({ newQuote });
      const gapQuote = selectedGapQuote({ newQuote });
      const twpQuote = selectedTwpQuote({ newQuote });

      const { quoteInitialization, vehicleFormValues, rateFormValues } = newQuote;

      const retailPriceOfGap = _.get(gapQuote, 'retailCostRequired');
      return {
        quoteInitialization,
        vehicleFormValues,
        allPlansSelected: isAllPlansSelected(newQuote),
        rateFormValues,
        loading: _.get(page, 'loading'),
        quoteType,
        saleDate: formatSaleDateMoment(saleDate),
        newQuote,
        dealer: _.get(dealer, 'details'),
        dealerState: _.get(dealer, 'details.address.state'),
        retailPriceOfGap,
        contractYear: _.get(dealer, 'details.rating.contractYear'),
        gapContractYear: _.get(dealer, 'details.rating.gapContractYear'),
        vscQuote,
        gapQuote,
        twpQuote,
        extraCoverages: _.values(newQuote.extraCoverages),
        maximumLoanAllowed: _.get(gapQuote, 'maximumLoanAllowed'),
      };
    },
    { storeRateFormValues, clearState, createIssuedContract, deleteIssuedContract, notifSend },
  ),
  WithUnloadHandler,
)(Rate);

const SaleDateInput = props => {
  const { label, description } = props;

  const [field, meta, { setValue, setTouched }] = useField(props);
  const { value, onChange, ...restField } = field;

  return (
    <FormGroup>
      {label && <ControlLabel>{label}</ControlLabel>}
      {description && <label>{description}</label>}
      <FormControl
        {...props}
        {...restField}
        value={value ? moment(value).format('YYYY-MM-DD') : value}
        onChange={e => {
          const newValueRaw = e.target.value;
          if (setValue)
            setValue(newValueRaw ? formatSaleDateMoment(moment(newValueRaw)) : null, true);
          setTouched(true);
        }}
        isInvalid={!!meta.error && meta.touched}
        type="date"
      />
      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
    </FormGroup>
  );
};

const CreateDuplicateContractConfirmation = ({
  show,
  disabled,
  onConfirm,
  onClose,
  pendingQuoteType,
  existingContract,
}) => {
  const [keepContractTypes, setKeepContractTypes] = useState([]);

  const omittedContractTypes = quoteTypeDifference(existingContract.contractType)(pendingQuoteType);
  const replacedContractTypes = quoteTypeIntersection(pendingQuoteType)(
    existingContract.contractType,
  );

  const omittedContractText = quoteTypeTitle(omittedContractTypes);

  const handleToggleContractCb = contractType => () => {
    if (keepContractTypes.includes(contractType))
      setKeepContractTypes(keepContractTypes.filter(t => t !== contractType));
    else setKeepContractTypes([...keepContractTypes, contractType]);
  };

  // To allow something to keep, we need to have something to cancel first.
  // If there is some replaced contracts, they will be cancelled
  // Or, if there is more than one omitted contract, user can select some to cancel.
  const enableChooseToKeep = replacedContractTypes.length > 0 || omittedContractTypes.length > 1;

  // If there is no replaced contracts, and user selected all to keep, we have nothing to cancel.
  const hasNothingToCancel =
    replacedContractTypes.length === 0 &&
    omittedContractTypes.every(t => keepContractTypes.includes(t));

  const keepCheckBoxes = enableChooseToKeep
    ? omittedContractTypes.map(contractType => (
        <Checkbox
          key={contractType}
          checked={keepContractTypes.includes(contractType)}
          onChange={handleToggleContractCb(contractType)}
        >
          Keep {contractTypeTitle(contractType)} Contract
        </Checkbox>
      ))
    : [];

  return (
    <NotificationModal
      show={show}
      disabled={disabled}
      disabledConfirm={hasNothingToCancel}
      title="Contract Already Exists"
      message={`An existing ${quoteTypeTitle(
        existingContract.contractType,
      )} contract was submitted for ${existingContract.vin}, would you like to cancel it?`}
      confirmText={`Cancel Existing & Submit New`}
      cancelText={`Continue (Submit New)`}
      onConfirm={() => onConfirm(keepContractTypes)}
      onCancel={onClose}
      data-test-name="Rate-CreateDuplicateContractConfirmation"
    >
      {keepCheckBoxes.length > 1 && (
        <div>
          <hr />
          <p>
            The previous contract included {omittedContractText} contracts, which are not included
            anymore.
          </p>
          <p>Select contracts you want to keep:</p>
          {keepCheckBoxes}
        </div>
      )}

      {keepCheckBoxes.length === 1 && (
        <div>
          <hr />
          <p>
            The previous contract included {omittedContractText} contract, which is not included
            anymore.
          </p>
          <p>Do you want to keep it?</p>
          {keepCheckBoxes}
        </div>
      )}
    </NotificationModal>
  );
};
