import React, { useEffect, useState, useMemo, useCallback } from 'react';
import _, { isUndefined, omitBy, pick } from 'lodash';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import { Button, Col, Row, Glyphicon } from 'react-bootstrap';
import Input from '../../../components/common/form/Input';
import Select from '../../../components/common/form/Select';
import { DateCalendar } from '../../../components/common/form/DateCalendar';
import SubmitButton from '../../../components/common/form/Submit';
import {
  textTrimRequired,
  money,
  minFinancedAmountLessCostOfGap,
  notBeforeDays,
  notInFuture,
  requiredIfTrue,
  stringErrorTest,
  minLoanAmountFlat,
  maxLoanAmountYup,
  maxKelleyBlueBookFlat,
  moneyTypeErrorMsg,
} from '../../../components/common/form/schema/common';
import { quoteMetaTestDataIfRequested } from '../../../utils/testData';
import {
  clearAllCoverageSelection,
  submitNewQuoteRequest,
  storeVehicleFormValues,
} from '../../../redux/modules/newQuote';
import {
  COMMERCIAL_TYPES,
  VEHICLE_CONDITION_TYPES,
  VEHICLE_CONDITION_OPTIONS,
  RELIABLE_CREDIT_ASSOCIATION_OPTIONS,
  BHPH_OPTIONS,
  LENDER_OPTIONS,
} from '../constants';
import ContractTypeCheckboxes from './form/ContractTypeCheckboxes';
import CommercialMessage from './CommercialMessage';
import RideSharingMessage from './RideSharingMessage';
import { quoteTypeIncludesVSCxTWP, QUOTE_TYPE_ONLY_GAP } from '../../../constants/quoteType';
import { QUOTE_TYPE_ONLY_VSC, quoteTypeIncludesGAP } from '../../../constants/quoteType';
import { getVerificationOverride } from '../../../redux/modules/verificationOverride';
import { getDealer } from '../../../redux/modules/dealer';
import { getDealerSettings } from '../../../redux/modules/dealerSettings';
import QuoteSummary from './QuoteSummary';
import FinalQuote from './FinalQuote';
import ValidationSelect from '../../../components/common/ValidationSelect';
import { collapseVin } from '../../../components/common/normalize/vin';
import { collapseNumberMoneyHideZeroCents } from '../../../components/common/normalize/numberMoney';
import { collapseNumberInt } from '../../../components/common/normalize/numberInt';
import { WithUnloadHandler } from '../../new-quote/WithUnloadHandler';
import { isAllPlansSelected } from '../isAllPlansSelected';
import { InterceptNavigation } from '../../new-quote/WithUnloadHandler';
import { formikSetErrorFieldsTouchedHelper } from '../../../utils/formikSetFieldsHelper';

export const isLoanAmountRequired = ({ quoteType }) => quoteTypeIncludesGAP(quoteType);

export const isVehicleConditionRequired = ({ quoteType, dealerState }) =>
  quoteTypeIncludesGAP(quoteType) && dealerState === 'IN';

export const isMsrpRequired = ({ quoteType, dealerState, vehicleCondition }) =>
  quoteTypeIncludesGAP(quoteType) &&
  dealerState === 'IN' &&
  vehicleCondition === VEHICLE_CONDITION_TYPES.NEW;

export const isNadaAverageRetailValueRequired = ({ quoteType, dealerState, vehicleCondition }) =>
  quoteTypeIncludesGAP(quoteType) &&
  dealerState === 'IN' &&
  vehicleCondition === VEHICLE_CONDITION_TYPES.USED;

export const isFinancedAmountLessCostOfGapRequired = ({ quoteType, dealerState }) =>
  quoteTypeIncludesGAP(quoteType) && dealerState === 'IN' && dealerState !== 'CA';

export const isReliableCreditAssociatedRequired = ({ quoteType, dealerState }) =>
  quoteTypeIncludesGAP(quoteType) && dealerState === 'AZ';

export const isBhphAvailable = ({ quoteType }) => quoteTypeIncludesGAP(quoteType);

export const isKelleyBlueBookRequiredAtBeginning = ({ quoteType }) =>
  quoteTypeIncludesGAP(quoteType);

const COMMERCIAL_OPTIONS = [
  {
    value: COMMERCIAL_TYPES.NON_COMMERCIAL,
    label: 'Non-Commercial',
  },
  {
    value: COMMERCIAL_TYPES.COMMERCIAL,
    label: 'Commercial',
  },
];

function getCommercialOptionsForDealer(dealerInfo) {
  if (
    _.get(dealerInfo, 'address.state') === 'CA' &&
    _.get(dealerInfo, 'rating.gapContractYear') === '2023A'
  ) {
    return COMMERCIAL_OPTIONS.concat({
      value: COMMERCIAL_TYPES.RIDE_SHARING,
      label: 'Ride-Sharing',
    });
  }
  return COMMERCIAL_OPTIONS;
}

function Quote({
  router,
  route,
  location,
  loading,
  eula,
  quoteInitialization,
  vehicleFormValues,
  storeVehicleFormValues,
  dealerHasUsedNewFeature,
  dealerState,
  dealerInfo,
  getVerificationOverride,
  verificationOverride,
  contractYear,
  gapContractYear,
  getDealer,
  getDealerSettings,
  submitNewQuoteRequest,
  plansLoaded,
  allPlansSelected,
}) {
  useEffect(() => {
    if (!dealerState) {
      getDealer();
      getDealerSettings();
    }
  }, [dealerState]);
  const [loanAmountCleared, setLoanAmountCleared] = useState(false);
  const [loanAmountOverride, setLoanAmountOverride] = useState(false);

  const showSummary = location && location.pathname.endsWith('/plans');
  const formDisabled = showSummary;

  useEffect(() => {
    if (!eula || !quoteInitialization) router.replace('/quote/eula');
    else if (showSummary && !vehicleFormValues) router.replace('/quote/vehicle');
  }, [router, eula, quoteInitialization, showSummary, vehicleFormValues]);

  const schema = useMemo(
    () =>
      Yup.object().shape({
        quoteType: Yup.array()
          .label('Contract Type')
          .min(1, 'Contract Type required')
          .required('Contract Type required'),
        vin: textTrimRequired.length(17).label('VIN'),
        msrp: Yup.number()
          .typeError(moneyTypeErrorMsg)
          .test(money)
          .test(requiredIfTrue(isMsrpRequired, { dealerState })),
        vehiclePurchasePrice: Yup.number()
          .typeError(moneyTypeErrorMsg)
          .label('Vehicle Purchase Price')
          .test(money),
        nadaAverageRetailValue: Yup.number()
          .typeError(moneyTypeErrorMsg)
          .test(money)
          .test(requiredIfTrue(isNadaAverageRetailValueRequired, { dealerState })),
        financedAmountLessCostOfGap: Yup.number()
          .typeError(moneyTypeErrorMsg)
          .test(money)
          .test(minFinancedAmountLessCostOfGap)
          .test(requiredIfTrue(isFinancedAmountLessCostOfGapRequired, { dealerState })),
        reliableCreditAssociation: Yup.string().test(
          requiredIfTrue(isReliableCreditAssociatedRequired, { dealerState }),
        ),
        kelleyBlueBook: Yup.number()
          .typeError(moneyTypeErrorMsg)
          .test(money)
          .test(requiredIfTrue(isKelleyBlueBookRequiredAtBeginning, { dealerState }))
          .test(
            stringErrorTest((value, { parent: { loanAmount } }) =>
              maxKelleyBlueBookFlat(value, { dealerState, gapContractYear, loanAmount }),
            ),
          ),
        bhph: Yup.mixed()
          .label('BHPH')
          .test((value, { parent: { quoteType }, createError }) => {
            if (isKelleyBlueBookRequiredAtBeginning({ dealerState, quoteType }) && !value)
              return createError({ message: 'Select BHPH status' });

            return true;
          }),
        loanAmount: loanAmountOverride
          ? Yup.number()
              .typeError(moneyTypeErrorMsg)
              .test(money)
              .test(requiredIfTrue(isLoanAmountRequired))
          : Yup.number()
              .typeError(moneyTypeErrorMsg)
              .test(money)
              .test(
                stringErrorTest((value, { parent: { kelleyBlueBook } }) =>
                  minLoanAmountFlat(value, {
                    dealerState,
                    gapContractYear,
                    kelleyBlueBook,
                  }),
                ),
              )
              .test(maxLoanAmountYup({ dealerState, contractYear }))
              .test(requiredIfTrue(isLoanAmountRequired)),
        vehicleSaleDate: Yup.date('MM/DD/YYYY')
          .test(notInFuture)
          .test(notBeforeDays(20))
          .required('Required'),
      }),
    [dealerState, contractYear, gapContractYear, loanAmountOverride],
  );

  const handleSubmit = async values => {
    storeVehicleFormValues(values);
    router.push(`/quote/plans`);
  };

  useEffect(() => {
    if (!vehicleFormValues) getVerificationOverride();
  }, []);

  useEffect(() => {
    if (vehicleFormValues && showSummary && !plansLoaded) {
      const { quoteType: quoteTypeArray, ...passThroughValues } = vehicleFormValues;

      submitNewQuoteRequest({ ...passThroughValues, quoteType: quoteTypeArray.join(',') });
    }
  }, [vehicleFormValues, showSummary, plansLoaded]);

  const testData = quoteMetaTestDataIfRequested() || {};

  const stepBackButton = (
    <Button
      bsStyle="link"
      onClick={() => {
        router.goBack();
      }}
      bxSize="xsmall"
    >
      <Glyphicon glyph="pencil" />
      &nbsp; Change vehicle information
    </Button>
  );

  const [initialValues] = useState(() => ({
    commercialType: COMMERCIAL_TYPES.NON_COMMERCIAL,
    vehicleCondition: VEHICLE_CONDITION_TYPES.USED,
    ...testData,
    ...(vehicleFormValues ||
      omitBy(
        pick(quoteInitialization, [
          'commercialType',
          'vehicleCondition',
          'vin',
          'quoteType',
          'vehicleMileage',
          'msrp',
          'nadaAverageRetailValue',
          'financedAmountLessCostOfGap',
          'vehiclePurchasePrice',
          'bhph',
          'kelleyBlueBook',
          'loanAmount',
          'reliableCreditAssociation',
        ]),
        isUndefined,
      )),
  }));

  const [newQuoteType, setNewQuoteType] = useState(null);

  return (
    <div className="container">
      {
        <Formik
          validateOnBlur
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({
            handleReset,
            handleSubmit,
            values,
            errors,
            isValid,
            isSubmitting,
            setFieldValue,
            setTouched,
          }) => {
            const {
              quoteType: quoteTypeArray,
              commercialType,
              vehicleCondition,
              vin,
            } = values || {};

            const quoteType = quoteTypeArray && quoteTypeArray.join(',');

            useEffect(() => {
              if (commercialType === COMMERCIAL_TYPES.COMMERCIAL) {
                if (dealerHasUsedNewFeature) {
                  setFieldValue('vehicleCondition', VEHICLE_CONDITION_TYPES.USED);
                }
                setFieldValue('quoteType', [QUOTE_TYPE_ONLY_VSC]);
              }
            }, [commercialType, dealerHasUsedNewFeature]);

            useEffect(() => {
              if (newQuoteType) {
                setFieldValue('quoteType', newQuoteType);
                setNewQuoteType(null);
              }
            }, [newQuoteType]);

            useEffect(() => {
              storeVehicleFormValues(values);
            }, [values]);

            const setErrorsTouched = useCallback(() => {
              formikSetErrorFieldsTouchedHelper({ setTouched })(errors);
            }, [setTouched, errors]);

            useEffect(() => {
              if (commercialType === COMMERCIAL_TYPES.RIDE_SHARING) {
                setFieldValue('quoteType', [QUOTE_TYPE_ONLY_GAP]);
              }
            }, [commercialType]);

            useEffect(() => {
              if (quoteTypeIncludesVSCxTWP(quoteType) && dealerHasUsedNewFeature) {
                setFieldValue('vehicleCondition', VEHICLE_CONDITION_TYPES.USED);
              }
            }, [quoteType, dealerHasUsedNewFeature]);

            useEffect(() => {
              if (!quoteTypeIncludesGAP(quoteType) && !loanAmountCleared) {
                console.warn(
                  'This is a deprecated workflow, we should consider another route for ',
                );
                setFieldValue('loanAmount', undefined);
                // @TODO - We need to replace in the long term. This will not work well.
                setLoanAmountCleared(true);
              }
            }, [loanAmountCleared, quoteType]);

            useEffect(() => {
              if (_.get(verificationOverride, 'length')) {
                const overrides = verificationOverride.filter(({ vin: vin2 }) => vin === vin2);
                setLoanAmountOverride(overrides.length > 0);
              }
            }, [vin, verificationOverride]);

            return (
              <form
                onReset={handleReset}
                noValidate={true}
                onSubmit={handleSubmit}
                data-test-id="QuoteMetaForm"
              >
                {!formDisabled && (
                  <InterceptNavigation
                    router={router}
                    route={route}
                    location={location}
                    blockForwardAttempt={!isValid}
                    onForwardBlocked={setErrorsTouched}
                  />
                )}
                <Row>
                  <Col xs={12} md={8}>
                    <Select
                      name="commercialType"
                      label="Designation"
                      placeholder="Choose one"
                      options={getCommercialOptionsForDealer(dealerInfo)}
                      data-test-id="QuoteMetaForm-commercialType"
                      disabled={formDisabled}
                    />
                    {dealerHasUsedNewFeature && (
                      <Select
                        name="vehicleCondition"
                        label="Vehicle Condition"
                        options={VEHICLE_CONDITION_OPTIONS}
                        disabled={quoteTypeIncludesVSCxTWP(quoteType) || formDisabled}
                        data-test-id="QuoteMetaForm-vehicleCondition"
                      />
                    )}
                    <Select name="lenderType" label="Lender" options={LENDER_OPTIONS} />
                  </Col>
                  <Col xs={12} md={4}>
                    <ContractTypeCheckboxes
                      name="quoteType"
                      disabled={
                        commercialType === COMMERCIAL_TYPES.COMMERCIAL ||
                        commercialType === COMMERCIAL_TYPES.RIDE_SHARING ||
                        formDisabled
                      }
                      dealerInfo={dealerInfo}
                    />
                    {commercialType === COMMERCIAL_TYPES.RIDE_SHARING && (
                      <div style={{ marginBottom: 15 }}>
                        <Glyphicon glyph="info-sign" style={{ color: 'royalblue' }} />
                        &nbsp;
                        <small>
                          Ride Sharing Coverage is only available on GAP in CA
                          <br />
                          and cannot be accompanied by VSC or TWP Coverage
                        </small>
                      </div>
                    )}
                  </Col>
                  <Col xs={12}>
                    <CommercialMessage
                      show={commercialType && commercialType === COMMERCIAL_TYPES.COMMERCIAL}
                    />
                    <RideSharingMessage
                      show={commercialType && commercialType === COMMERCIAL_TYPES.RIDE_SHARING}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Input
                      type="text"
                      name="vin"
                      label="VIN"
                      placeholder="Full VIN - eg. 1GNSKJKC6GR123038"
                      data-test-id="VehicleBasicInformationForm-vin"
                      disabled={formDisabled}
                      collapse={collapseVin}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Input
                      type="text"
                      name="vehicleMileage"
                      label="Current Mileage"
                      collapse={collapseNumberInt}
                      placeholder="eg. 4681"
                      data-test-id="VehicleBasicInformationForm-vehicleMileage"
                      disabled={formDisabled}
                    />
                  </Col>
                </Row>
                <Row>
                  {isVehicleConditionRequired({ quoteType, dealerState }) && (
                    <Col xs={12} md={6}>
                      <Select
                        name="vehicleCondition"
                        label="Vehicle Condition"
                        component={ValidationSelect}
                        options={VEHICLE_CONDITION_OPTIONS}
                        data-test-id="VehicleBasicInformationForm-vehicleCondition"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                  {isMsrpRequired({ quoteType, dealerState, vehicleCondition }) && (
                    <Col xs={12} md={6}>
                      <Input
                        name="msrp"
                        collapse={collapseNumberMoneyHideZeroCents}
                        label="MSRP"
                        placeholder="eg. 22000"
                        data-test-id="VehicleBasicInformationForm-msrp"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  {isNadaAverageRetailValueRequired({
                    quoteType,
                    dealerState,
                    vehicleCondition,
                  }) && (
                    <Col xs={12} md={6}>
                      <Input
                        name="nadaAverageRetailValue"
                        collapse={collapseNumberMoneyHideZeroCents}
                        label="NADA Average Retail Value"
                        placeholder="eg. 16500"
                        data-test-id="VehicleBasicInformationForm-nadaAverageRetailValue"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                  {isFinancedAmountLessCostOfGapRequired({
                    quoteType,
                    dealerState,
                  }) && (
                    <Col xs={12} md={6}>
                      <Input
                        name="financedAmountLessCostOfGap"
                        collapse={collapseNumberMoneyHideZeroCents}
                        label="Financed Amount less cost of GAP, credit insurance, and vehicle service contract"
                        placeholder="eg. 7600"
                        data-test-id="VehicleBasicInformationForm-financedAmountLessCostOfGap"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Input
                      name="vehiclePurchasePrice"
                      collapse={collapseNumberMoneyHideZeroCents}
                      label="Vehicle Price"
                      placeholder="eg. 20000"
                      data-test-id="VehicleBasicInformationForm-vehiclePurchasePrice"
                      disabled={formDisabled}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <DateCalendar
                      name="vehicleSaleDate"
                      label="Vehicle Sale Date"
                      placeholder={`eg. ${moment().format('MM/DD/YYYY')}`}
                      data-test-id="VehicleBasicInformationForm-vehicleSaleDate"
                      disabled={formDisabled}
                    />
                  </Col>
                </Row>
                <Row>
                  {isBhphAvailable({ quoteType }) && (
                    <Col xs={12} sm={4} md={3}>
                      <Select
                        name="bhph"
                        label="BHPH Deal"
                        placeholder="Choose one"
                        options={BHPH_OPTIONS}
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                  {isKelleyBlueBookRequiredAtBeginning({ quoteType, dealerState }) && (
                    <Col xs={12} sm={8} md={3}>
                      <Input
                        name="kelleyBlueBook"
                        collapse={collapseNumberMoneyHideZeroCents}
                        label="Kelley Blue Book Retail Value"
                        placeholder="eg. 22000"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  {isLoanAmountRequired({ quoteType }) && (
                    <Col xs={12} md={6}>
                      <Input
                        name="loanAmount"
                        collapse={collapseNumberMoneyHideZeroCents}
                        label="Amount Financed"
                        placeholder="eg. 4681"
                        step="0.01"
                        data-test-id="VehicleBasicInformationForm-loanAmount"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                  {isReliableCreditAssociatedRequired({ quoteType, dealerState }) && (
                    <Col xs={12} md={6}>
                      <Select
                        name="reliableCreditAssociation"
                        label="Is the lender Reliable Credit Association or not?"
                        placeholder="Choose one"
                        options={RELIABLE_CREDIT_ASSOCIATION_OPTIONS}
                        data-test-id="VehicleBasicInformationForm-reliableCreditAssociation"
                        disabled={formDisabled}
                      />
                    </Col>
                  )}
                </Row>
                {!showSummary && (
                  <Row className="margin-top-20px">
                    {' '}
                    <Col xs={12}>
                      <SubmitButton
                        loadingText="Retrieving Quote..."
                        isValid={isValid}
                        isSubmitting={isSubmitting}
                        errors={errors}
                        setTouched={setTouched}
                        data-test-name="VehicleBasicInformationForm-Buttons"
                      >
                        Generate Quote
                      </SubmitButton>
                    </Col>
                  </Row>
                )}
              </form>
            );
          }}
        </Formik>
      }
      {showSummary && (
        <div
          style={{
            borderBottom: '1px dashed rgba(0,0,0,0.3)',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          {stepBackButton}
        </div>
      )}
      {showSummary && <QuoteSummary router={router} setNewQuoteType={setNewQuoteType} />}
      {showSummary && (
        <InterceptNavigation
          router={router}
          route={route}
          location={location}
          blockForwardAttempt={!allPlansSelected}
        />
      )}
      {showSummary && allPlansSelected && <FinalQuote router={router} location={location} />}
      {showSummary && !allPlansSelected && (
        <div>
          <i>Select plan for each contract type to continue.</i>
        </div>
      )}
      {showSummary && !loading && <div>{stepBackButton}</div>}
    </div>
  );
}

const mapFromConnect = state => ({
  eula: _.get(state, 'newQuote.eula'),
  allPlansSelected: isAllPlansSelected(state.newQuote),
  quoteInitialization: _.get(state, 'newQuote.quoteInitialization'),
  vehicleFormValues: _.get(state, 'newQuote.vehicleFormValues'),
  dealerHasUsedNewFeature: _.get(state, 'dealerSettings.data.features.usedNew', false),
  dealerInfo: _.get(state, 'dealer.details'),
  contractYear: _.get(state, 'dealer.details.rating.contractYear'),
  gapContractYear: _.get(state, 'dealer.details.rating.gapContractYear'),
  dealerState: _.get(state, 'dealer.details.address.state'),
  verificationOverride: _.get(state, 'verificationOverride.data'),
  loading: _.get(state, 'page.loading'),
  plansLoaded: !!_.get(state, 'newQuote.plans'),
});

export default compose(
  connect(mapFromConnect, {
    getVerificationOverride,
    getDealer,
    getDealerSettings,
    storeVehicleFormValues,
    submitNewQuoteRequest,
    clearAllCoverageSelection,
  }),
  WithUnloadHandler,
)(Quote);
