export const COMMERCIAL_TYPES = {
  NON_COMMERCIAL: 'NON_COMMERCIAL',
  COMMERCIAL: 'COMMERCIAL',
  RIDE_SHARING: 'RIDE_SHARING',
};

export const VEHICLE_CONDITION_TYPES = {
  NEW: 'NEW',
  USED: 'USED',
};

export const VEHICLE_CONDITION_OPTIONS = [
  {
    value: VEHICLE_CONDITION_TYPES.USED,
    label: 'Used',
  },
  {
    value: VEHICLE_CONDITION_TYPES.NEW,
    label: 'New',
  },
];

export const RELIABLE_CREDIT_ASSOCIATION_OPTIONS = [
  {
    label: 'Reliable Credit Association',
    value: 'AZRCA',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const LENDER_OPTIONS = [
  {
    label: 'AmeriCredit',
    value: 'Americredit',
  },
  {
    label: 'Capital One',
    value: 'Capital1',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const BHPH_OPTIONS = ['No', 'Yes'];
